import * as React from 'react';
import {useState, useEffect, useCallback} from 'react';
import Grid from '@mui/material/Grid';
import {is_mode} from '../../initialize/initialize.js';
import API from '../../initialize/API.js';

//rankingリスト用
var g_devices = []

export default function SidePanelRanking( props ) {
  const { devices, heat, handleChangeSelectedDevice, setRanking } = props;
  const [rankings, setRankings] = useState([]);
	let intervalRanking= React.useRef();

	useEffect(() => {
		g_devices = devices

		//ischeckedの更新用
		let out = []
		rankings.forEach( r => {
			g_devices.forEach( d => {
				if(d.id == r.id){
					r.ischecked = d.ischecked
					out.push( r )
				}
			})
		})
		setRankings( out );

	},[devices]);


	//ランキングリストを一定周期で取得する
	useEffect(() => {
		if(is_mode() == "live" && heat){
		clearInterval( intervalRanking.current );
		intervalRanking =  setInterval(() => {
			let onSuccess = (res) => {
				if(!res.data)
					return
				if(!res.data.Ranklist)
					return
				let out = []
				let max_id=0;
				let max_spd=0;
				//max speed search
				g_devices.forEach( d => {
					if(d.category == 1){
						if(d.speed_5s > max_spd){
							max_id = d.id;
							max_spd = d.speed_5s;
						}
					}
				})
				res.data.Ranklist.forEach( r => {
					g_devices.forEach( d => {
						if(d.athlete_id == r.athlete_id){
							let row = {
								"id": d.id,
								"is_max": max_id==d.id ? true : false,
								"rank": r.rank,
								"sailno": d.no,
								"nation": d.nation,
								"speed_5s": d.speed_5s,
								"fwd_dist": r.fwd_dist,
								"nation": d.nation,
								"is_goal": r.is_goal,
								"name": d.firstname,
								"ischecked": d.ischecked,
							}
							out.push( row )
						}
					})
				})
				setRankings( out );
				setRanking( out );
			}
			let rank_host = process.env.REACT_APP_RANKING_URL;
			API.getRankingWithHeat({ onSuccess: onSuccess, host: rank_host,  heat_id: heat.id })
		} ,5000);
		}
	},[heat]);


	const goal = (
		<img className={"goal-flag"} src={"/goal_flag.png"}  alt={"goal"} />
	)

	const side_panel_ranking = (
		<Grid style={{ zIndex: 100 }} item xs={12} sm={3}>
			<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sail# &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Spd<span style={{fontSize:'8px'}}>(km/h)</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dist <span style={{fontSize:'8px'}}>(m)</span></div>
			{rankings.length > 0 ? (
				rankings.map((r) => (
					<button key={r.id} className={r.ischecked ? 'btn-athlete-selected':'btn-athlete'} onClick={()=>{handleChangeSelectedDevice(r.id)}} >
						<Grid container >
							<Grid  item xs={1}>
								<span className={'ranking-rank'}>{r.rank}</span>
							</Grid>
							<Grid  item xs={1}>
								<img className={'nation-flag-2'} src={`/NationalFlag/${r.nation.toLowerCase()}.png`} alt={r.nation} />
							</Grid>
							<Grid  item xs={3}>
								<span className={r.ischecked ? 'ranking-athlete-no-selected' : 'ranking-athlete-no'}>   {r.sailno}</span>
							</Grid>
							<Grid  item xs={2}>
								<span className={r.is_max? 'ranking-spd-max' : 'ranking-spd'} > {r.speed_5s} </span>
							</Grid>
							<Grid  item xs={2}>
								<span className={'ranking-dist'}> {r.is_goal ? goal : r.fwd_dist}</span>
							</Grid>
							<Grid  item xs={3}>
								<span className={'ranking-name'}> {r.name} </span>
							</Grid>
						</Grid>
					</button>
				))
			) : (
				<>no data.</>
			)}
		</Grid>
	);


  return (
		<>
		{side_panel_ranking}
		</>
	)
}
