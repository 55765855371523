import * as React from 'react';
import {useState, useEffect, useCallback} from 'react';
import Grid from '@mui/material/Grid';
import {is_mode} from '../../initialize/initialize.js';
import API from '../../initialize/API.js';

//rankingリスト用
var g_devices = []

//距離と速度からタイム差を計算
function calcDelayTime(dist, spd){

	let speed = spd<1? 30 : spd;

  // 距離をキロメートルに変換し、時速で割って時間を計算
	const delay = (dist / 1000) / speed;
	// 時間を分に変換
	const totalMinutes = delay * 60;
	const minutes = Math.floor(totalMinutes);
	// 残りの秒数を計算
	const seconds = Math.round((totalMinutes - minutes) * 60);

	if(spd < 1) {
		return "--:--"
	}else{
		// 分:秒のフォーマットで返す
		return `${minutes}:${seconds.toString().padStart(2, '0')}`;
	}
}

export default function SidePanelRoadRace( props ) {
  const { devices, heat, handleChangeSelectedDevice, course } = props;
  const [rankings, setRankings] = useState([]);
  const [delayedTime, setDelayedTime] = useState(null);//最後に選択したデバイス
	let intervalRanking= React.useRef();

	useEffect(() => {
		g_devices = devices
	},[devices]);

	//ランキングリストを一定周期で取得する
	useEffect(() => {
		if(is_mode() == "live" && heat){
		clearInterval( intervalRanking.current );
		intervalRanking =  setInterval(() => {
			let onSuccess = (res) => {
				if(!res.data)
					return
				if(!res.data.Ranklist)
					return
				let out = []
				let max_id=0;
				let max_spd=0;
				//max speed search
				g_devices.forEach( d => {
					if(d.category == 1){
						if(d.speed_5s > max_spd){
							max_id = d.id;
							max_spd = d.speed_5s;
						}
					}
				})
				let count = 0;
				let dist  = 0;
				let spd  = 0;
				res.data.Ranklist.forEach( r => {
					g_devices.forEach( d => {
						if(d.athlete_id == r.athlete_id){
							let row = {
								"id": d.id,
								"is_max": max_id==d.id ? true : false,
								"rank": r.rank,
								"sailno": d.no,
								"nation": d.nation,
								"firstname": d.firstname,
								"club": d.club,
								"speed_5s": d.speed_5s,
								"fwd_dist": r.fwd_dist,
								"nation": d.nation,
								"is_goal": r.is_goal,
								"name": d.firstname,
								"ischecked": d.ischecked,
								"lap": r.lap,
								"color_code": d.color_code,
							}
							out.push( row )

							//checkedのユーザー数をカウント
							if(d.ischecked && count < 2){
								dist = r.fwd_dist - dist;
								spd = d.speed_5s;
								count=count+1;
							}
						}
					})
				})
				setRankings( out );
				
				//タイム差を更新
				if(count > 0){
					setDelayedTime( calcDelayTime(dist, spd) )
				}else{
					setDelayedTime( false )
				}
			}
			let rank_host = process.env.REACT_APP_RANKING_URL;
			API.getRankingWithHeat({ onSuccess: onSuccess, host: rank_host,  heat_id: heat.id })
		} ,1000);
		}
	},[heat]);


	const goal = (
		<img className={"goal-flag"} src={"/goal_flag.png"}  alt={"goal"} />
	)

	const side_panel_ranking = (
		<Grid style={{ zIndex: 100 }} item xs={12} sm={3}>

			<div className={'lap-head'} style={{ display: "flex", alignItems: "center" }}>
				<div className={"lap-disp"} > LAP &nbsp;&nbsp;&nbsp; <span style={{color:"rgba(255, 127, 80, 1)"}}>{rankings.length > 0 ? ( Number(course.lap)-1 < Number(rankings[0].lap)+1 ? "FINAL" : rankings[0].lap+1):""}</span> { rankings.length > 0 ? "/"+ course.lap : "" }  </div>
			</div>

			<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No# &nbsp;&nbsp;&nbsp; Spd<span style={{fontSize:'8px'}}>(km/h)</span>&nbsp;&nbsp;&nbsp; Time</div>
			{rankings.length > 0 ? (
				rankings.map((r) => (

					<div key={r.id}>
					<button key={r.id} className={r.ischecked ? 'btn-athlete-selected':'btn-athlete'} onClick={() => { handleChangeSelectedDevice(r.id); }} >
						<Grid container >
							<Grid  item xs={1}>
								<span className={'ranking-rank'}>{r.rank}</span>
							</Grid>
							<Grid  item xs={1}>
								<img
									style={{border: `2pt solid ${r.color_code}`}}
									className={'team-flag'}
									src={`/j-pro/${r.club}.png`}
									alt={r.club}
									onError={(e) => {
										e.target.src = '/j-pro/default.png';
									}}
								/>

							</Grid>
							<Grid  item xs={4}>
								<span className={'ranking-firstname'}>{/*r.fwd_dist*/r.firstname}</span>
							</Grid>
							<Grid  item xs={2}>
								<span className={r.ischecked ? 'ranking-athlete-no-selected' : 'ranking-athlete-no'}>   {r.sailno}</span>
							</Grid>
							<Grid  item xs={2}>
								<span style={{ textAlign: 'center' }} className={r.is_max? 'ranking-spd-max' : 'ranking-spd'} > {r.speed_5s} </span>
							</Grid>
							<Grid  item xs={2}>
								<span className={'ranking-dist'}> {r.is_goal ? goal : ( r.rank==1 ? "Leader" : calcDelayTime(r.fwd_dist, r.speed_5s) )}</span>
							</Grid>
						</Grid>
					</button>
					</div>
				))
			) : (
				<>no data.</>
			)}
		</Grid>
	);

  return (
		<>
		{side_panel_ranking}
		{
			delayedTime && (
				<div className="delayed-time">
						先頭集団との差<br />
						{delayedTime}
				</div>
			)
		}
		</>
	)
}
