import API from './API.js';
import Device from './device';

// initialize_replay
export function init_replay(setDevices, setCenter, setOnline, replayDataUrl, setDataRange, setCurrentReplayTimeSec, setWind, setCourse, setSpinner){

  let success = function(res) {

    let r = res.data;

    //Devices
    let deviceMap = new Map();
		let lastDevice;
    r.devices.map(function(d, i) {
      deviceMap.set( d.id, new Device(d.id, d));
			lastDevice = d;
    });
    setDevices(deviceMap);


		if(r.course.start_lat != ""){ //コースが設定されている
      let center = [(Number(r.course.start_lat) + Number(r.course.end_lat)) /2, (Number(r.course.start_long) + Number(r.course.end_long)) /2];
      setCenter(center);
		}else{
			let g = lastDevice.gpses.slice(-1)[0];
			let center = [(Number(g.lat) ) , (Number(g.lon)) ];
      setCenter(center);
		}

		//Course
		if(r.course.checkpoints_string != ""){
			let checkpoints = JSON.parse(r.course.checkpoints_string)
			setCourse({
				net             : r.course.net,
				goal_start_lat  : r.course.goal_start_lat,
				goal_start_long : r.course.goal_start_long,
				goal_end_lat    : r.course.goal_end_lat,
				goal_end_long   : r.course.goal_end_long,
				checkpoints     : checkpoints,
				geojson         : r.course.geojson ?  JSON.parse(r.course.geojson):[],
				})
		}

    //Slider
    var max_time = new Date("Wed, 12 Sep 1000 00:38:03 GMT");
    let i;
    deviceMap.forEach((d, i) => {
      let time = d.max_time();
      if (time > max_time){
        max_time = time
      }
    });
    var min_time = new Date("Wed, 12 Sep 7000 00:38:03 GMT");
    deviceMap.forEach((d, i) => {
      let time = d.min_time();
      if (time < min_time){
        min_time = time
      }
    });

    //Setter Slider
    setDataRange({
        min: min_time/1000,
        max: max_time/1000,
        step: 1
    });
    setCurrentReplayTimeSec(min_time/1000);

    //Wind Information
    if(r.wind_direction && r.max_wind){
      setWind({
        "wind_direction" : r.wind_direction,
        "max_wind" : r.max_wind,
        "min_wind" : r.min_wind} );
    } else if (r.wind_direction ){
      setWind({ "wind_direction" : r.wind_direction});
    }

    setOnline({replay: true});

    setSpinner(false);
  }
  let error = function(res){
    alert('failed to get replay data:', res)
  }

  API.getReplayData({url: replayDataUrl, onSuccess:success, onError:error});

}


/*
    this.center = function(){
      return this.middleOf(this.startCoordinate(), this.endCoordinate())
    }

    this.middleOf = function(coord1, coord2){
      var p1  = turf.point(coord1.reverse())
      var p2  = turf.point(coord2.reverse())
      var midpoint = turf.midpoint(p1, p1);
      return midpoint.geometry.coordinates.reverse()
    }

    this.max_time = function(){
      var max_time = new Date("Wed, 12 Sep 1000 00:38:03 GMT");
      var i
      for (i = 0; i < this.replayDevices.length; i++){
        var time = this.replayDevices[i].max_time()
        if (time > max_time){
          max_time = time
        }
      }
      return max_time
    }

    this.min_time = function(){
      var min_time = new Date("Wed, 12 Sep 7000 00:38:03 GMT");
      var i
      for (i = 0; i < this.replayDevices.length; i++){
        var time = this.replayDevices[i].min_time()
        if (time < min_time){
          min_time = time
        }
      }
      return min_time
    }

    this.move = function(time){
      const format_time = time.getFullYear()
        + '-' + ('0' + (time.getMonth() + 1)).slice(-2)
        + '-' + ('0' + time.getDate()).slice(-2)
        + ' ' + ('0' + time.getHours()).slice(-2)
        + ':' + ('0' + time.getMinutes()).slice(-2)
        + ':' + ('0' + time.getSeconds()).slice(-2);
      const logs = this.logs;

      this.replayDevices.forEach(function(d, i) {
        let final_geos = []; // ジオフェンスの最終状態を格納するための配列
        let geo_hash = {}; // geofence_id

        if(logs.length != 0){
          for(let idx = 0; idx < logs.length; idx++){
            // ジオフェンスの発生時間がリプレイの時間より前の時、かつ、シリアル番号が一致する時に発火
            if(logs[idx].created_at <= format_time && logs[idx].device_id == d.deviceId){
              // event_typeが7の時、ジオフェンスに入る
              if(logs[idx].event_type == 7){
                //hashに登録
                geo_hash[logs[idx].geofence_id] = logs[idx].geofence
              // event_typeが8の時、ジオフェンスから出る
              } else if(logs[idx].event_type == 8){
                //hashから削除
                delete geo_hash[logs[idx].geofence_id]
              }
            }
          }
        }

        //ジオフェンス表示の処理
        for (let key in geo_hash) {
          final_geos.push(geo_hash[key])
          d.searchstr += geo_hash[key] // searchstrに対象ジオフェンスを追加
        }
        d.geofence = final_geos.join(',');  // Deviceのgeofenceにジオフェンスの最終状態を格納
        d.move(time)
      })
    }
  }

*/
