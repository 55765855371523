import * as React from 'react';
import PropTypes from 'prop-types';
import {useState, useEffect, useCallback} from 'react';
import {render} from 'react-dom';

//Initialize
import {init, is_mode, is_triathlon_view, is_individual_view, is_terrain_view, getReplayDataUrl, getEleOffset, URLParams} from '../../initialize/initialize.js';
import {init_replay} from '../../initialize/initialize_replay.js';

//My compornent
import MapView from '../map/map_view.js';
import DeckGLView from '../deck-gl/deckgl_view.js';
import Athletes from '../list/list';
import Settings from '../settings/settings';
import Slider from './Slider';
import Spinner from "./spinner";
import {formatDT, formatHMS, is_training, formatTime, is_replay, map_style, showElevation, elevationZoom} from '../../helpers';
import VideoDesktop from '../video/video_desktop.js';
import DisplayMobile from './display_mobile.js';
import TeamName from './team_name.js';
import { isAthlete } from '../../initialize/device_helpers.js';
import { handleCopySpeeds, handleCopySpeeds60 } from './copy_data';
import { handleChangeEleOffset } from './terrain';
import { handleSelectDataRange, handleChangeIncrementSecond, handleChangePlayState, autoPlay  } from './replay';
import SidePanelHandler from './side_panel_handler';

//CSS
import '../../index.css';

// MUI
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MapIcon from '@mui/icons-material/Map';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SettingsIcon from '@mui/icons-material/Settings';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


//ウインドウサイズの取得
function getWindowSize() {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];
    const width = w.innerWidth || e.clientWidth || g.clientWidth;
    const height = w.innerHeight|| e.clientHeight|| g.clientHeight;
    return {
            width: width,
            height: height
    }
}

//Bug? triathlon device
var init_flg = false;
var init_time = new Date();

// App 本体
export default function Main() {


  //Display Size
  const w = getWindowSize();
  let mobile = false;
  let ipad = false;
  if(w.width < 500){
    mobile = true;
  } else if(w.width < 1100){
    ipad = true;
  }else{
  }


  // State
  const [devices, setDevices] = useState([]);
  const [center, setCenter] = React.useState([35.28167445342043, 139.67306039427112]);

  const [style, setStyle] = useState(map_style() ? map_style() : 'mapbox://styles/i01210ml/clg571dks000501qna35qk8uh');
  const [online, setOnline] = useState();
  const [dataRange, setDataRange] = useState({
        min: 0,
        max: 0,
        step: 1
    });
  const [currentReplayTimeSec, setCurrentReplayTimeSec] = useState(0);
  const [isPlaying, setIsPlaying] = useState();
  const [incrementSecond, setIncrementSecond] = useState(60); // x10 play initial
  const [wind, setWind] = useState(null);
  const [showWindLine, setShowWindLine] = useState(true);//ウインドラインの表示
  const [course, setCourse] = useState(null);
  const [spinner, setSpinner] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState('spd');
  const [heat, setHeat] = useState(null);
  const [heatName, setHeatName] = useState(null);
  const [delay, setDelay] = useState(null);
  const [eleOffset, setEleOffset] = useState(0);
  //WindLIneライン
  const [windLine, setWindLine] = useState(null);
	//Ranking
  const [ranking, setRanking] = useState(null);

	//オートプレイ用タイマー
	const timerRef= React.useRef();

  // トライアスロンビューでは、最もナンバーの小さいアスリートを選択状態にしてからdeviceをセットする
  const setTriathlonDevices = (devices) => {
		if(new Date() - init_time > 4000){
			if(!init_flg){
				let minDevice;
				for (let device of devices.values()) {
					if (isAthlete(device.category)) {
						if (!minDevice || Number(device.no) < Number(minDevice.no)) {
							minDevice = device;
						}
					}
				}
				if ((minDevice) && is_triathlon_view() ) {
					minDevice.ischecked = true;
				}
			setDevices(devices);
			init_flg=true;
			return;
			}
		}
		setDevices(devices);
  }


  //Initialize Heat
  useEffect(() => {
    if(is_replay()){
      init_replay(setTriathlonDevices, setCenter, setOnline, getReplayDataUrl(), setDataRange, setCurrentReplayTimeSec, setWind, setCourse, setSpinner);
			const queryParams = new URLSearchParams(window.location.search);
			const play_speed = queryParams.get('play_speed');
			if(play_speed){
				setIncrementSecond(play_speed);
			}
			clearInterval(timerRef.current)
    }else if(is_mode() == "live"){
      init(setTriathlonDevices, setCenter, setOnline, setWind, setCourse, setHeat, setHeatName);
    }
		setEleOffset(getEleOffset())
  }, []);


	const autoPlayStart = () =>{
		autoPlay(timerRef, incrementSecond, setCurrentReplayTimeSec, devices, setTriathlonDevices, dataRange, currentReplayTimeSec, setIsPlaying)
	}

	//auto play when initialized replay data
	useEffect(() => {
		if(online){
			if(online.replay){
				setTimeout(autoPlayStart, 3000)
			}
		}
	}, [online]);




  const height = 
    (mobile ?
      is_replay() ? (showElevation()? "40vh" :"55vh") : (showElevation()? "40vh" : "55vh")
    : 
      is_replay() ? (showElevation()? "75vh" : "95vh") : (showElevation()? "80vh" : "100vh")
		)

  // ライブ映像の高さを保存 -> 地図の高さの調整に使用
  const [liveHeight, setLiveHeight] = useState(0);
  const handleChangeLiveHeight = useCallback((height) => {
    setLiveHeight(height);
  }, []);

  // タイトルバーの高さを保存 -> 地図の高さの調整に使用
  const [titleBarHeight, setTitleBarHeight] = useState(0);
  const handleChangeTitleBarHeight = useCallback((height) => {
    setTitleBarHeight(height);
  }, []);

  // 地図の高さを調整
  const height_desktop = (() => {
    return `calc(${height} - ${liveHeight}px - ${titleBarHeight}px)`;
  })();

	if(!devices)
		return <></>



  // 選択状態を更新したdeviceMapを作成し、setDevicesでdevicesを更新
	const handleChangeSelectedDevice = (serial) => {
		let newDevices = new Map();
		[...devices].forEach(([key, d]) => {
      if (key === serial) {
        d.ischecked = !d.ischecked;
      } else {
        d.ischecked = d.ischecked;
      }
			/*
			if(d.ischecked){
				setWindLine({deviceId: d.id});
			}else{
				if(windLine.deviceId == d.id){
					setWindLine(null);
				}
			}
			*/
			newDevices.set(key, d)
		});
		setDevices(newDevices);
  }


  const controls = (
    <>
      <Spinner loading={spinner}/>
      <Slider
        dataRange={dataRange}
        values={currentReplayTimeSec}
        handleSelectDataRange={(updatedReplayTimeSec)=>handleSelectDataRange(timerRef, setCurrentReplayTimeSec, updatedReplayTimeSec, incrementSecond, isPlaying, setIsPlaying, devices, setDevices, dataRange, setDelay, currentReplayTimeSec)}
        />
      <Grid container spacing={1}>
        <Grid item xs={3} style={{'background-color':'#000000', 'textAlign': 'left'}} className={'nowtime'}>{formatTime(currentReplayTimeSec, mobile)}</Grid>
        <Grid item xs={6}  style={{'background-color':'#000000'}} >
						{spinner == false ?
							(<select className={'play_speed'} onChange={(e)=>handleChangeIncrementSecond(e, isPlaying, setIncrementSecond, incrementSecond, setCurrentReplayTimeSec, devices, setDevices, dataRange, currentReplayTimeSec, setIsPlaying, timerRef)} defaultValue={incrementSecond}>
								<option value="1">x1</option>
								<option value="5" >x5</option>
								<option value="10">x10</option>
								<option value="30">x30</option>
								<option value="60">x60</option>
								<option value="120">x120</option>
								<option value="300">x300</option>
							</select>)
							: (<></>)
						}
            {isPlaying
              ?<button onClick={()=>handleChangePlayState(timerRef, isPlaying, setIsPlaying, incrementSecond, devices, setDevices, setCurrentReplayTimeSec,dataRange, currentReplayTimeSec)}> Stop </button>
              :<button onClick={()=>handleChangePlayState(timerRef, isPlaying, setIsPlaying, incrementSecond, devices, setDevices, setCurrentReplayTimeSec,dataRange, currentReplayTimeSec)}> Play </button>
            }
            { is_training() && <button onClick={()=>handleCopySpeeds(devices, currentReplayTimeSec)}> Copy[+-20] </button>}
            { is_training() && <button onClick={()=>handleCopySpeeds60(devices, currentReplayTimeSec)}> Copy[0-60] </button>}
            { is_terrain_view() && <input type="number" onChange={(e)=>handleChangeEleOffset(e, setEleOffset)} value={eleOffset} />}
        </Grid>
        <Grid item xs={3} style={{'background-color':'#000000'}} >
          <div className={'endtime'}>{formatTime(dataRange.max, mobile)}</div>
        </Grid>
      </Grid>
    </>
  )

	const disp_desktop = (
    <>
      <TeamName  style={{zIndex: 100}} name={heat ? heat.discipline+" "+heat.race+ " "+heatName : heatName} onHeight={handleChangeTitleBarHeight} />
      <Grid container >
        <Grid item xs={12} sm={9}>
					{is_terrain_view() ? 
					<DeckGLView center={center} devices={devices} setDevices={handleChangeSelectedDevice} style={style} online={online} height={height_desktop}  ele_offset={eleOffset}
									handleClickMarker={handleChangeSelectedDevice} windLine={windLine} setWindLine={setWindLine} ranking={ranking}
									wind={wind} showWindLine={showWindLine} course={course} setStyle={setStyle} is_triathlon_view={is_triathlon_view()}
                                    showElevation={showElevation()} elevationZoom={elevationZoom()} />
					:
					<MapView center={center} devices={devices} style={style} online={online} height={height_desktop}  ranking={ranking}
									handleClickMarker={handleChangeSelectedDevice} windLine={windLine} setWindLine={setWindLine}
									wind={wind} showWindLine={showWindLine} course={course} setStyle={setStyle} is_triathlon_view={is_triathlon_view()}
                                    showElevation={showElevation()} elevationZoom={elevationZoom()} />
					}
        </Grid>
				<SidePanelHandler is_mode={is_mode()} is_mobile={mobile} devices={devices} heat={heat} handleChangeSelectedDevice={handleChangeSelectedDevice}
                          height_desktop={height_desktop} selectedValue={selectedValue} setSelectedValue={setSelectedValue} setDevices={setDevices}
													course={course} setRanking={setRanking}  ranking={ranking}/>
      </Grid>
      {is_replay() && (controls)}
      <VideoDesktop onHeight={handleChangeLiveHeight} delay={delay}/>
    </>
	)

  const map_mobile = (
    <>
					{is_terrain_view() ? 
					<DeckGLView center={center} devices={devices} setDevices={handleChangeSelectedDevice} style={style} online={online} height={height}  ele_offset={eleOffset}
									handleClickMarker={handleChangeSelectedDevice} windLine={windLine} setWindLine={setWindLine} ranking={ranking}
									wind={wind} showWindLine={showWindLine} course={course} setStyle={setStyle} is_triathlon_view={is_triathlon_view()}
                                    showElevation={showElevation()} elevationZoom={elevationZoom()} />
					:
					<MapView center={center} devices={devices} style={style} online={online} height={height} 
									handleClickMarker={handleChangeSelectedDevice} ranking={ranking}
									wind={wind} showWindLine={showWindLine} course={course} setStyle={setStyle} is_triathlon_view={is_triathlon_view()}
                                    showElevation={showElevation()} elevationZoom={elevationZoom()} />
					}
    </>
  )

  return (
    <>
      { mobile?
               <DisplayMobile
                  teamName={heatName}
                  mapComponent={map_mobile}
                  sidePanelComponent={<SidePanelHandler is_mode={is_mode()} is_mobile={mobile} devices={devices} heat={heat} handleChangeSelectedDevice={handleChangeSelectedDevice} height_desktop={height_desktop} selectedValue={selectedValue} setSelectedValue={setSelectedValue} setDevices={setDevices} course={course} setRanking={setRanking} />}
                  controlsComponent={is_replay() && (controls)}
                  delay={delay}
 setRanking={setRanking} 
                />
              : disp_desktop
      }
    </>
  );
}

