import React, { useEffect, useState } from 'react';
import VideoMobile from '../video/video_mobile';
import TeamName from './team_name';

function DisplayMobile(props) {
    const { teamName, mapComponent, sidePanelComponent, controlsComponent, delay } = props;
    const [selectedTab, setSelectedTab] = useState('MAP');
    const [tabVisible, setTabVisible] = useState(false);

    useEffect(() => {
        /* URLパラメータに'youtube'が存在する時だけセレクトタブを表示する */
        if (window.location.search.includes('youtube')) {
            setTabVisible(true);
        }
    }, []);

    const handleTabClick = (tab) => {
      setSelectedTab(tab);
    }

    const tab = (
        <div style={{ display: 'flex', justifyContent: 'space-between', background: 'white', height: '40px' }}>
            {['MAP', 'LIVE'].map(tabName => (
                <div
                    key={tabName}
                    onClick={() => handleTabClick(tabName)}
                    style={{
                        flex: '1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: selectedTab === tabName ? 'black' : 'gray',
                        cursor: 'pointer',
                    }}
                >
                    {tabName}
                </div>
            ))}
        </div>
    )

    const space = <p></p>

    return (
        <>
            <TeamName name={teamName}/>
            { tabVisible && tab }
            <div style={{ display: selectedTab === 'MAP' ? 'block' : 'none' }}>
                {mapComponent}
                {controlsComponent}
                {space}
                {sidePanelComponent}
            </div>
            <div style={{ display: selectedTab === 'LIVE' ? 'block' : 'none' }}>
                <VideoMobile delay={delay} />
            </div>
        </>
    )
}

export default React.memo(DisplayMobile);
