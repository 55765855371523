import * as React from 'react';
import {useState, useEffect, useCallback} from 'react';
import { isAthlete } from '../../initialize/device_helpers.js';
import {to_d} from '../../helpers';

export default function AthleteList( props ) {

  const { devices, handleChangeSelectedDevice, selectedValue } = props;
  //map -> list
  let devs = [...devices].map( ([v,k]) => {
    return k
  })

  //sort
  let sort_target = 'no'; //ソート対象を変数で設定
  devs.sort((a, b) => a[sort_target] - b[sort_target]);
  devs.sort((a, b) => b.ischecked - a.ischecked);//選択されたものは先頭へ


  //Find max speed
  let max_id = "";
  let maxspd = 0;
  devs.forEach((device, i)=>{
    if(maxspd < device.speed_5s){
      maxspd = device.speed_5s;
      max_id = device.id;
    }
  });


	return(
			<>
			<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RaceNo# &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Spd<span style={{fontSize:'8px'}}>(km/h)</span></div>
			{devs.length > 0 ? 
			devs.map( (d) => (
            d.showMarker &&
						isAthlete(d.category) && 
								(<button key={d.serial} onClick={()=>{handleChangeSelectedDevice(d.serial)}} className={d.ischecked ? 'btn-athlete-selected':'btn-athlete'} >
									<span style={{background: d.color}} className={'athlete-head'} >　 </span>
									<div className={d.no ? d.ischecked ? 'athlete-no-selected':'athlete-no' : ''}>{d.no}</div>
									<div className={d.no ? d.ischecked ? 'athlete-name-selected':'athlete-name' : d.ischecked ? 'athlete-name-selected-without-no':'athlete-name-without-no'}>{ d.fullname }</div>
									{selectedValue === 'spd' ?
										<>
											<span className={d.id == max_id ? 'athlete-spd-max'  : 'athlete-spd'}>{d.speed_5s}</span>
											<span className={d.id == max_id ? 'athlete-unit-max' : 'athlete-unit'} >/km</span>
										</>
									:
										<>
										<span className={'athlete-spd'}>{to_d(d.pace1000m)}</span>
										<span className={'athlete-unit'}color={'white'} > 分/km</span>
										</>
									}
								</button>)
			)) : <></>
			}
		</>
	)
}
