import React, { useEffect } from 'react';
import Iframe from 'react-iframe';

import { generateYoutubeUrl } from '../../helpers';
import { useYoutubeState } from '../../hooks/useYoutubeState';

const liveWidth = 426;
const liveHeight = 240;
const liveMargin = 16;

const iframeWrapperStyle = {
  display: 'block',
  overflow: 'hidden',
  width: liveWidth,
  height: liveHeight,
  borderRadius: '10px',
  transform: 'translateY(0px)',
  border: '3px solid #eee',
};

const VideoDesktop = (props) => {
  const { onHeight, delay } = props;
  const { videoIds, videoOptions, isAutoPlay } = useYoutubeState(delay);

  useEffect(() => {
    if (videoIds.length === 0) {
      return;
    }
    onHeight(liveHeight + liveMargin * 2);
  }, [videoIds, onHeight]);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', 'background-color':'#000000' }}>
      {videoIds.map((id, i) => (
        <div
            key={id}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1, // 余った分を均等に分配する -> 各要素の横幅が均等になる
              minWidth: `calc(100% / ${videoIds.length} - ${liveMargin * 2}px)`, // flexWrapを指定しているので、minWidthを下回る場合は折り返す
              margin: liveMargin,
            }}
          >
            <div key={id} style={iframeWrapperStyle}>
              <Iframe
                url={generateYoutubeUrl(id, isAutoPlay, videoOptions[i])}
                width={liveWidth}
                height={liveHeight}
                display="initial"
                position="relative"
              />
            </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(VideoDesktop);
