import React from 'react';
import { Layer, Source } from "react-map-gl";

const layout = {
    "line-join": "round",
    "line-cap": "round",
};

const paint = {
    "line-color": ["get", "color"],
    "line-width": 5,
    "line-opacity": ["get", "opacity"],
    //"line-opacity": 1,
};

function CourseMap(props) {
  const { visibleRoutes, courseGeojson } = props;

  if (!courseGeojson || !courseGeojson.features) return;


  // filter example: ["all", ["!=", ["get", "comment"], "Swim"]] => Swimをcommentに持たないフィーチャのみ通す。
  // all: 以降に続くすべての条件を満たすことを要求。
  const routeFilter = () => {
    return [
      "all",
      visibleRoutes.Swim.exists && visibleRoutes.Swim.visible ? undefined : ["!=", ["get", "comment"], "Swim"],
      visibleRoutes.Bike.exists && visibleRoutes.Bike.visible ? undefined : ["!=", ["get", "comment"], "Bike"],
      visibleRoutes.Run.exists && visibleRoutes.Run.visible ? undefined : ["!=", ["get", "comment"], "Run"],
    ].filter((condition) => condition !== undefined);
  };

  return (
    <Source type="geojson" data={courseGeojson}>
      {/* ドット線以外の表示 */}
      <Layer
        id="solid-course"
        type="line"
        source="data"
        filter={["all", routeFilter(), ["!=", ["get", "dotted"], true]]}
        layout={layout}
        paint={paint}
      />
      {/* ドット線の表示 */}
      <Layer
        id="dotted-course"
        type="line"
        source="data"
        filter={["all", routeFilter(), ["==", ["get", "dotted"], true]]}
        layout={layout}
        paint={{
            ...paint,
            "line-dasharray": [1, 2],
        }}
      />
    </Source>
  );
}

export default React.memo(CourseMap);
