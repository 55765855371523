import * as React from 'react';

// Configration Info
//Production
export function PRO_RACEMANEGEMENT_HOSTNAME() {return 'https://hawkcast.herokuapp.com'}
export function PRO_GOAL_CHECKER_HOSTNAME(){return 'https://stg-race-goal-checker.herokuapp.com'}
export function PRO_websocketURL(){return 'wss://hawkcast-data-spectators.herokuapp.com/api/ws/hawkcast/v3/'}

//Staging
export function STG_RACEMANEGEMENT_HOSTNAME(){return 'https://stg-hawkcast.herokuapp.com'}
export function STG_GOAL_CHECKER_HOSTNAME(){return 'https://stg-goal-checker.herokuapp.com'}
export function STG_websocketURL(){return 'wss://stg-hawkcast-data.herokuapp.com/api/ws/hawkcast/v3/'}

//Develop
export function DEV_RACEMANEGEMENT_HOSTNAME(){return 'http://localhost:3000'}
export function DEV_GOAL_CHECKER_HOSTNAME(){return 'http://localhost:8888'}
export function DEV_websocketURL(){return 'ws://localhost:8080/api/ws/hawkcast/v3/'}
