import * as React from 'react';
import {useState, useEffect} from 'react';
import { Marker } from 'react-map-gl';
import IconArrow from './icon_arrow';
import IconSup from './icon_sup';

const posDiffDefault = {x: 0, y: 0};
const posDiffName = {x: 0, y: -14.5};
const posDiffTooltip = {x: 28, y: -42};
const posDiffFocus = {x: 0, y: -15};
const posDiffFocusNoName = {x: 5, y: -5};
const posDiffFocusTooltip = {x: 28, y: -42};

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function Animation(props) {
  const {id, position, no, name, speed, time, color, focus, showMarker = true, bearing, setWindLine,  duration, category, animate_flg, is_triathlon_view, emergency, handleClickMarker, rank} = props;
  const [currentPos, setCurrentPos]   = useState({lat:0, lon:0, angle:0});
  const [showTooltip, setShowTooltip] = useState(false);
  const [posDiff, setPosDiff] = useState(posDiffDefault);
  const [prevTime, setPrevTime]   = useState(null);
  const showName = focus || showTooltip || !is_triathlon_view;

  //Duration
  let _duration = 1000 // initial value
  if(duration)
    _duration = duration

  //positionが更新のたびに起動
  useEffect(() => {

    if (animate_flg === false){
      return;
    }

    //更新がなければ処理しない
    if( time == prevTime ){
      //console.log("same time.")
      return;
    } else{
      setPrevTime( time );
    }

    //デバイスを移動
    if((Math.abs(position.lat) < 180 )){
      //パラメタ
      //移動距離
      let d_angle = position.angle -  currentPos.angle;

      if(Math.abs(d_angle) > 180){
        if(d_angle > 0){
          d_angle = d_angle - 360;
        } else {
          d_angle = d_angle + 360;
        }
      }


      let v = {
        lat:   (position.lat   - currentPos.lat),
        lon:   (position.lon   - currentPos.lon),
        angle: ( d_angle ),
      }
      // 移動だけ
      var move = function(setPos, v, prev, from, sumtime){
        let now = Date.now()
        let dt = now  - prev;
        sumtime = sumtime + dt;

        // end animation
        if(sumtime > _duration){
          return;
        }

        let d = dt/_duration;
				let a = from.angle + v.angle * d;
        let p = {
          lat:   from.lat + v.lat * d,
          lon:   from.lon + v.lon * d,
          angle: isNaN(a)? 0 : a
        }
        //動かす
        setPos(p);

        //Animation描画
        requestAnimationFrame(()=>move(setPos, v, now, p, sumtime));
      }

      //Animation描画
      requestAnimationFrame(()=>move(setCurrentPos, v, Date.now(), currentPos, 0));
    }
  },[position, animate_flg])

  // TooltipとNameの表示によるアイコン位置の調整
  useEffect(() => {
		// Focusかつ、Name非表示
		// Focus時
    if (focus && showTooltip ) {
      setPosDiff(posDiffFocusTooltip);
      setWindLine({deviceId: id});
			//console.log("focustool")
      return;
    } else if (focus && showMarker){
      setPosDiff(posDiffFocus);
      setWindLine({deviceId: id});
			//console.log("focus")
      return;
		} else if (showTooltip) {
      setPosDiff(posDiffTooltip);
			//console.log("tooltip")
		}else if (showMarker) {
      setPosDiff(posDiffName);
			//console.log("showname")
    } else {
      setPosDiff(posDiffDefault);
			//console.log("default")
    }
 
   setWindLine(null);

  },[showTooltip, setWindLine, showName, id, focus, showMarker])

  // focusが外れたらTooltipを閉じる
  useEffect(() => {
    if (!focus) {
      setShowTooltip(false);
    }
  },[focus])


	const onClick = (showTooltip) =>{
		setShowTooltip(!showTooltip);
		handleClickMarker(id);
	}


  // 一つのMarkerでname, tooltip, icon, focus_iconを表示しているので、表示・非表示の切り替えで位置調整が必要。
  // 上記を別々のMarkerで表示するとその必要がなくなる可能性あり。(しかしMarkerの数が増えるのでパフォーマンスに影響がでる可能性あり。)
  return(
    <>
    <Marker longitude={currentPos.lon} latitude={currentPos.lat}>

      {((showName && showMarker) || focus) && (

        <>
        <div className="tooltip-ranking" >{rank}</div>
        <div className="tooltip" onClick={()=>(setShowTooltip(!showTooltip))}>
          { no }
          {showTooltip && (
            <>
              <div className="tooltip-name">{name}</div>
              <div className="tooltip-speed">SPD:{speed}km/h</div>
              <div className="tooltip-time">UPD:{time}</div>
            </>
          )}
        </div>
        </>
      )}

      { category == 1 && <IconArrow offset={posDiff} rotate={currentPos.angle - bearing} color={color} focus={focus} showMarker={showMarker} emergency={emergency} onClick={() => onClick()} /> }
      { category == 2 && <IconSup offset={posDiff} rotate={currentPos.angle - bearing} color={color} focus={focus} onClick={() => (setShowTooltip(!showTooltip))} /> }


    </Marker>
    </>
  );
}

export default React.memo(Animation);
