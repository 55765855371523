import * as React from 'react';
import PropTypes from 'prop-types';
import {useState, useEffect, useCallback} from 'react';
import {init, updateHeatTime, getEleOffset, URLParams} from '../../initialize/initialize.js';
import Speeds from '../speed/speeds';

//CSS
import '../../index.css';

export default function WWC(props) {

  const [devices, setDevices] = useState([]);
  const [center, setCenter] = React.useState([35.28167445342043, 139.67306039427112]);
  const [online, setOnline] = useState();
  const [wind, setWind] = useState(null);
  const [course, setCourse] = useState(null);
  const [heat, setHeat] = useState(null);
  const [heatName, setHeatName] = useState(null);
  const [heatTime, setHeatTime] = useState(null);
  const [heatStatus, setHeatStatus] = useState(null);


	useEffect(() => {
		init( setDevices, setCenter, setOnline, setWind, setCourse, setHeat, setHeatName);
		updateHeatTime(setHeatTime, setHeatStatus);
	},[]);



	const disp_wwc = (
    <>
      <div className={'speed-container'}>
        <div className={'heat-time'}>{heatTime}</div>
        <div className={heatStatus}></div>
        <Speeds heat={heat} devices={devices} />
      </div>
      <div className={'ax-on'}></div>
    </>
	)

  return (
    <>
      {disp_wwc}
    </>
  );
}
