import * as React from 'react';

export default class Heat {

  constructor(id, status, course, started_at, ended_at, sensor_infos, wind_direction, max_wind, min_wind, name){
    this.id = id
    this.status = status
    this.course = course
    this.started_at = new Date(started_at)
    this.ended_at = new Date(ended_at)
    this.devices = {}
    this.sensor_infos = sensor_infos
    this._sensorIds = null
    this.wind_direction = wind_direction
    this.max_wind = max_wind
    this.min_wind = min_wind
    this.name = name

    if (this.course == null){
      alert('Couse is undefiend')
    }
    else{
      this.buoyNames = this.course.route.split(',')
    }
    this._buoyDevices = undefined
    this._goalDevices = undefined

    this.isActive = function(){
      return !(this.sensor_infos.length == 0)
    }

    this.isInclude = function(sensorId){
      return this.sensorIds().includes(parseInt(sensorId))
    }

    /*
    this.goalDevices = function(){
      if (this._goalDevices != undefined){
        return this._goalDevices
      }
      this._goalDevices = _.filter(heat.devices, (d) => d.isGoal())
      return this._goalDevices
    }

    this.lastBuoyDevice = function(){
      return this.buoyDevices()[this.buoyDevices().length - 1]
    }

    this.buoyDevices = function(){
      if (this._buoyDevices != undefined){
        return this._buoyDevices
      }
      this._buoyDevices = []
      // Iterateover buoynames except for last(G1)
      for (var i=0; i < this.buoyNames.length - 1; i++){
        var buoyName = _.findKey(heat.devices, {'name': this.buoyNames[i]})
        if (buoyName == undefined){
          console.error('Could not find bouy ' + this.buoyNames[i])
          this._buoyDevices = []
          return []
        }else{
          this._buoyDevices.push(this.devices[buoyName])
        }
      }
      return this._buoyDevices
    }

    this.sensorIds = function(){
      if (this._sensorIds != null){
        return this._sensorIds
      }else{
        this._sensorIds = _(this.sensor_infos).map('serial').map((s) => parseInt(s)).value()
        return this._sensorIds
      }
    }

    */
    this.startCoordinate = function(){
      return [parseFloat(this.course.start_lat), parseFloat(this.course.start_long)]
    }

    this.endCoordinate = function(){
      return [parseFloat(this.course.end_lat), parseFloat(this.course.end_long)]
    }

    this.center = function(){
      return this.middleOf(this.startCoordinate(), this.endCoordinate())
    }

    this.goalStartCoordinate = function(){
      return [parseFloat(this.course.goal_start_lat), parseFloat(this.course.goal_start_long)]
    }

    this.goalEndCoordinate = function(){
      return [parseFloat(this.course.goal_end_lat), parseFloat(this.course.goal_end_long)]
    }

    this.goalCenter = function(){
      return this.middleOf(this.goalStartCoordinate(), this.goalEndCoordinate())
    }

    this.middleOf = function(coord1, coord2){
      var lat  = (coord1[0] + coord2[0]) / 2
      var lon  = (coord1[1] + coord2[1]) / 2
      return [lat, lon]
    }
  }
}
